.ons-layout.tabs > .box > .ons-item.link > .box {
  background: var(--black);
  color: var(--light-gray);
  font-weight: 700;
}

.ons-layout.tabs > .box > .ons-item.link.active > .box,
.ons-layout.tabs > .box > .ons-item.link > .box.active {
  background: var(--black);
  color: var(--yellow);
}

.ons-layout.tabs > .box > .ons-item.link:hover > .box,
.ons-layout.tabs > .box > .ons-item.link.active > .box {
  color: var(--yellow) !important;
}

.ons-layout.tabs > .box > .ons-item > .box {
  background-color: var(--black);
}

.ons-layout.tabs > .box > .ons-item > .box > .icon * {
  fill: var(--light-gray);
}
.ons-layout.tabs > .box > .ons-item.active > .box > .icon *,
.ons-layout.tabs > .box > .ons-item > .box:hover > .icon * {
  fill: var(--yellow);
}


/* Messenger */
.ons-layout.tabs.messenger-widget {
  background-color: #333;

  > .box > .ons-item > .box {
    background-color: var(--dark-gray);
  }

  > .box > .ons-item.link.active > .box {
    background: var(--dark-gray);
  }
}


/* Regardshop */
.ons-layout.tabs.regardShop > .box {
  background-color: #333;

  > .ons-item > .box {
    background-color: var(--dark-gray);
  }
}



