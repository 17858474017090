:root {
  --default-font: 'Titillium Web', sans-serif !important;
  --headline: 'Titillium Web', sans-serif !important;
  --headline-light: 'Titillium Web', sans-serif !important;

  --primary-color: var(--yellow);

  --freemovie-logo: url('../img/fmotd/logo.png');

  --menu-bg: var(--black);
  --menu-item-bg-hover: var(--yellow);
  --menu-item-bg-active: transparent;
  --menu-item-label-color: var(--light-gray);
  --menu-item-label-color-active: var(--yellow);
  --menu-item-label-font: 700 16px / 1 var(--headline);
  --menu-item-label-text-shadow: 0 0 2px rgba(36, 36, 36, 1);
  --menu-item-text-transform: none;
  --menu-item-icon-color: var(--light-gray);
  --menu-item-icon-color-active: var(--yellow);
  --menu-item-signup-bg: transparent;
  --menu-item-signup-bg-hover: var(--yellow);
  --menu-item-signup-icon-color: var(--green);
  --menu-item-signup-label-color: var(--green);
  --submenu-bg: transparent;
  --submenu-item-bg-hover: var(--yellow);
  --submenu-item-bg-active: transparent;
  --submenu-item-font: 700 14px / 1 var(--headline);
  --submenu-item-label-color: var(--light-gray);
  --submenu-item-label-color-active: var(--yellow);

  .fmotd-teaser-hook {
    .add,
    .current {
      --toolbar-list-color: var(--yellow);
    }
    .add {
      --toolbar-list-font: 700 16px var(--headline);
    }
  }

  --accordeon-btn-bg: #1e1e1e;
  --accordeon-btn-font: 700 15px var(--headline);
  --accordeon-btn-hover-color: white;
  --accordeon-btn-active-color: var(--yellow);
  --accordeon-btn-sexicons-bg: #1e1e1e;

  --panel-heading-font-large: 700 21px var(--headline);
  --alternative-product-background: #1e1e1e;
  --alternative-product-description-font: 16px var(--default-font);
  --alternative-product-logo-background: var(--black);

  .layout-panel {
    --personal-info-badge-background-color: #1e1e1e;
    --personal-info-badge-label-font: 600 14px var(--default-font);
    --personal-info-list-border-color: #1e1e1e;
    --personal-info-list-value-font: 14px var(--default-font);
  }

  --input-font: 14px var(--default-font);
  --input-bg: white;
  --input-border: 1px solid white;
  --input-color: #555;
  --input-border-focus: 1px solid var(--yellow);

  --messenger-input-border: 1px solid white;
  --messenger-input-border-focus: 1px solid var(--yellow);
  --messenger-input-boxshadow-focus: none;

  --messenger-border-color: black;

  --default-btn-font: 700 16px/24px var(--default-font);
  --default-btn-text-shadow: 0 0 2px rgba(36, 36, 36, 1);
  --default-btn-text-transform: unset;
  --default-btn-bg: linear-gradient(to bottom, #47a813 0%, #2e8102 100%);;
  --default-btn-border: 0;
  --default-btn-hover-bg: var(--green);

  --link-color: var(--yellow);
  --amateur-topic-badge-fg: white;
  --amateur-topic-badge-bg: #ffb71e;

  --overlay-reason-bg: var(--yellow);
  --overlay-reason-fg: white;
  --overlay-reason-fg-hover: white;
  --overlay-reason-font-weight: 600;
  --overlay-reason-text-shadow: 0 0 2px black;

  --yellow: #ffbd2f;
  --red: #e20000;
  --green: #47a813;
  --gray: #535353;
  --light-gray: #858585;
  --dark-gray: #242424;
  --black: #0e0e0e;

  --sedcard-button-height: 46px;
  --sedcard-button-bg: #1e1e1e;
  --sedcard-button-bg-hover: #ffbd2f;
  --sedcard-button-text-color-hover: #0e0e0e;
  --sedcard-button-attachment-icon: white;
  --sedcard-button-hover-attachment-icon: #0e0e0e;

  --dialog-button-bg: #0e0e0e;
  --dialog-button-bg-hover: #ffbd2f;
  --dialog-button-text-color: white;
  --dialog-button-text-color-hover: #0e0e0e;
  --dialog-button-attachment-icon: white;
  --dialog-button-hover-attachment-icon: #0e0e0e;

  --icon-offline-color: #979797;
  --icon-online-color: var(--green);

  --default-close-color: #d1d1d1;
  --default-close-color-hover: var(--yellow);

  --contact-bg: transparent;
  --contact-background-hover: var(--gray);
  --contact-background-active: black;
  --contact-background-flash-from: transparent;
  --contact-background-flash-to: var(--gray);
  --contact-nickname-color: var(--yellow);
  --contact-nickname-color-active: var(--yellow);
  --contact-nickname-font-size: 15px;
  --contact-nickname-font-weight: 700;
  --contact-close-color: var(--default-close-color);
  --contact-close-color-hover: var(--yellow);
  --contact-icon-color: white;
  --contact-timestamp-color: #bdbdbd;

  --contact-new-color: var(--green);
  --contact-new-flash-bg-from: rgba(71,168,19,0);
  --contact-new-flash-bg-to: rgba(71,168,19,1);
  --contact-new-flash-color-from: rgb(71,168,19);
  --contact-new-flash-color-to: rgb(255,255,255);
  --contact-new-font-size: 15px;
  --contact-new-font-weight: 700;

  --livecam-icon-normal: var(--green);
  --livecam-icon-voyeur: var(--red);
  --livecam-icon-exclusive: var(--yellow);
  --livecam-icon-sedcard-image-normal: var(--green);

  --livecam-button-normal-bg: linear-gradient(to bottom, #47a813 0%, #2e8102 100%);
  --livecam-button-normal-hover-bg: var(--green);
  --livecam-button-normal-hover-border: 1px solid var(--green);

  --livecam-button-exclusive-bg: linear-gradient(to bottom,#fec447 0, #ffae00 100%);
  --livecam-button-exclusive-color: var(--dark-gray);
  --livecam-button-exclusive-hover-bg: var(--yellow);
  --livecam-button-exclusive-hover-color: var(--black);
  --livecam-button-exclusive-hover-border: 1px solid var(--yellow);

  --livecam-button-voyeur-bg: linear-gradient(to bottom,#e20000 0,#940000 60%,#640505 100%);
  --livecam-button-voyeur-hover-bg: var(--red);
  --livecam-button-voyeur-hover-border: 1px solid var(--red);

  --livecam-button-additional-font-size: 13px;

  --livecam-show-close-icon-hover: var(--yellow);

  --livecam-show-button-bg: var(--gray);
  --livecam-show-button-color: var(--yellow);
  --livecam-show-button-hover-bg: var(--yellow);
  --livecam-show-button-hover-color: var(--black);
  --livecam-show-button-hover-border: 1px solid transparent;

  --livecam-show-send-button-bg: linear-gradient(to bottom, #47a813 0%, #2e8102 100%);
  --livecam-show-send-button-border: 1px solid transparent;
  --livecam-show-send-button-hover-bg: #47a813;
  --livecam-show-send-button-hover-border: 1px solid #47a813;

  --livecam-show-vibration-button-font-weight: 700;
  --livecam-show-vibration-button-font-size: 18px;
  --livecam-show-queue-vibration-button-font-size: 16px;

  --livecam-show-smiley-select-bg: rgba(0,0,0,.7);

  --livecam-show-smiley-button-hover-color: var(--black);

  --livecam-show-rebuy-seconds: var(--yellow);

  --livecam-motto: 700 18px/1 'Titillium Web', sans-serif;

  --hotornot-teaser-bg-url: url(../img/hotornot/bgTeaser.jpg);
  --hotornot-teaser-logo: url(../img/hotornot/logo.png);
  --hotornot-teaser-logo-en: url(../img/hotornot/logo.png);
  --hotornot-teaser-imgBox-border: 0;
  --hotornot-teaser-button-bg: linear-gradient(to bottom, #47a813 0%, #2e8102 100%);;
  --hotornot-teaser-button-border: 1px solid #39910a;
  --hotornot-teaser-button-hover-bg: #5d9900;

  --portal-heading-box-bg: linear-gradient(to right, black, var(--dark-gray) 45%);
  --portal-heading-box-padding: 10px;
  --portal-heading-box-color: var(--yellow);
  --portal-scroll-icon-color: var(--yellow);
  --portal-scroll-icon-hover-color: #e20000;

  --portal-small-box-bg: #444;
  --portal-small-box-title-font-size: 14px;
  --portal-small-box-title-color: #ffb71e;
  --portal-small-box-title-font-weight: 700;

  --floating-menu-bg: var(--green);
  --floating-menu-icon: white;

  --countdown-default-bg: var(--yellow);
  --countdown-default-border: 3px solid white;
  --countdown-default-color: var(--black);
  --countdown-default-font-weight: 700;
  --countdown-default-box-shadow: 0 2px 10px rgba(0,0,0,.4);

  --countdown-sidebar-top-position: unset;
  --countdown-sidebar-bottom-position: 20px;

  --countdown-sidebar-wellcum-top-position: 290px;
  --countdown-wellcum-bg: linear-gradient(to bottom, #e20000 0%, #940000 60%, #640505 100%);
  --countdown-wellcum-color: white;

  --doi-hint-text-color: #ffbd2f;
  --doi-hint-text-font-weight: 700;
  --doi-hint-link-color: white;

  --gameOverSplash: #f00;
  --game-headline: 700 16px 'Titillium Web', sans-serif;
  --games-timer-background: #3e3e3e;
  --games-timer-bar-background: linear-gradient(to right, var(--red) 0%, var(--green) 80px);

  --game-session-info-bg: #1e1e1e;
  --game-session-hover-bg: var(--black);

  --games-session-info-bg: #1e1e1e;
  --games-session-hover-bg: #242424;
  --games-session-text-transform: none;
  --games-session-win-color: var(--green);
  --games-session-lose-color: var(--red);
  --games-session-draw-color: var(--yellow);

  --game-icon-userTurn: var(--red);
  --game-icon-amateurTurn: var(--yellow);
  --gameColorPlus: var(--red);
  --gameColorElements: var(--black);

  --game-wrapper: #0e0e0e;

  --game-inviteBox-bg: var(--sedcard-button-bg);
  --game-inviteBox-border-color: var(--yellow);
  --game-inviteBox-bg-hover-text-color: var(--black);

  --game-suggestions-bg: var(--black);
  --game-suggestions-text-color: white;
  --game-suggestions-name-color: var(--yellow);
  --game-suggestions-hover-color: var(--red);

  --games-info-hover-background: var(--yellow);
  --game-inviteBox-sedcard-bg-hover-text-color: white;
  --game-inviteBox-sedcard-bg-hover-label-color: var(--green);

  --rules-color: var(--green);

}

onsw-comment-list {
  --comment-background-odd: #1e1e1e;
  --comment-background-even: var(--black);
}

onsw-latest-achievements {
  --achievement-font-color: white;
  --achievement-heading-font: 700 14px var(--headline);
}

onsw-game {
  connect-four-game {
    --game-wrapper: #0e0e0e;
    --game-background: #242424;
    --game-border: 1px solid #2c2c2c;
    --value1-background:radial-gradient(ellipse at center,var(--red) 52%, #c00000 55%, var(--red) 60%);
    --value2-background:radial-gradient(ellipse at center,var(--yellow) 52%, #d29b25 55%, var(--yellow) 60%);
    --value0-box-shadow: inset 0 0 6px 0 black, 0 2px 2px #3c3c3c;
    --value1-box-shadow: inset 0 0 2px #3c3c3c;
    --value2-box-shadow: inset 0 0 2px #3c3c3c;
  }
}
