ons-actionslider li.onsas-online, ons-actionslider li.onsas-online .onsas-inner {
  background: linear-gradient(to bottom, var(--green) 0%, #2e8102 100%);
}
ons-actionslider li.onsas-weblog, ons-actionslider li.onsas-weblog .onsas-inner {
  background: var(--red);
}
ons-actionslider li.onsas-livecam, ons-actionslider li.onsas-livecam .onsas-inner {
  background: linear-gradient(to bottom,#fec447 0, #ffae00 100%);
  text-shadow: 0 0 2px black;
}
ons-actionslider li.onsas-buddyconfirm, ons-actionslider li.onsas-buddyconfirm .onsas-inner,
ons-actionslider li.onsas-buddyrequest, ons-actionslider li.onsas-buddyrequest .onsas-inner {
  background: linear-gradient(to bottom, #e20000 0%, #940000 60%, #640505 100%);
}

