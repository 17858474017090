/** ## Messenger & Regard Shop (ero) */
.layout-container.messenger,
.layout-container.regard-shop,
.layout-container.regard-shop > .layout-overlay {
  --container-gap: 0;
  --container-background: var(--dark-gray);
  --messenger-border-color: var(--black);
  --messenger-background-hover: var(--gray);
  --messenger-background-active: var(--black);
  --messenger-highlight-color: var(--yellow);
  --messenger-scroll-border: 2px solid var(--black);
  --messenger-scroll-bg: #333;
  --messenger-scroll-bg-hover: var(--yellow);
  --messenger-scroll-icon-color: white;

  .layout-field {
    --input-gap: 10px;
    --input-label-font: 400 14px var(--default-font);
  }

  .layout-input {
    --input-bg: var(--messenger-input-bg);
    --input-border: var(--messenger-input-border);
    --input-color-focus: var(--messenger-input-color-focus);
    --input-border-focus: var(--messenger-input-border-focus);
    --input-boxshadow-focus: none;
  }

  .layout-item {
    --item-outline: 1px solid var(--black);
    --item-margin: 0 1px 1px 0;
    --item-background: var(--dark-gray);
    --item-background-hover: var(--messenger-background-hover);
    --item-background-active: var(--messenger-background-active);
    --item-highlight-color: var(--messenger-highlight-color);
  }

  .layout-panel {
    --panel-background: var(--black);
  }

  /** ### regard */
  --regard-shop-border: 1px solid var(--black);
  --regard-send-price-color: #555;
  --regard-link-color-active: var(--messenger-highlight-color);

  .layout-link.regard-category {
    --link-outline: 1px solid var(--black);
    --link-margin: 0 0 1px 0;
    --link-font: 700 15px var(--headline);
    --link-background-hover: var(--messenger-background-hover);
    --link-background-active: var(--messenger-background-active);
  }
}
