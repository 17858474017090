/* LOGIN */
.ons-item.link.header-login-widget > .box > .label {
  color: white;
}

.inline.header-login-widget > .box > .buttons > .box > .ons-item > .box {
  height: 30px;
  background: var(--yellow);

  &:hover {
    background: var(--green);
  }
}

.userPool-login.header-login-widget.ons-form > .box > label > input {
  border: 1px solid transparent !important;
}

.userPool-login.header-login-widget.ons-form > .box > label > input:focus {
  box-shadow: 0 0 8px rgba(99, 99, 99, 0.6);
}

.ons-item.button.header-login-widget > .box {
  height: 30px;
  background: var(--yellow);

  &:hover {
    background: var(--green);
  }
}
