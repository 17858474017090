/* ero */
.layout-container.layout-overlay {

  .layout-panel {
    --panel-background: #333;
  }

  .layout-toolbar {
    --toolbar-icon-color: #d1d1d1;
  }
}
