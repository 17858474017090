body {
  background: var(--dark-gray);
  color: #fff;
  font-size: 14px;
}

a {
  color: var(--yellow);
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
}

a.active,
.ons-item.link > .box,
.ons-item.link.active > .box,
.ons-item.link > .box.active {
  color: var(--yellow);
}

.ons-item.link > .box:hover {
  color: white;
}

.ons-item.link > .box > .label {
  text-decoration: none;
}

.feed-widget .ons-item.link > .box,
.ons-item.link.footermenu-widget > .box,
.layout-footer .ons-item.link > .box {
  color: white;

  > .label {
    text-decoration: underline;
  }

  &:hover {
    color: var(--yellow);
    text-decoration: none;
  }
}

.ons-layout.fixed-main:not(.overlay) {
  background: var(--dark-gray);
}

.ons-scroll {
  > .scroll-overlay > .bar > .slider {
    opacity: 0.4;
    transition: opacity .3s ease;
    background-color: var(--light-gray);

    &:hover {
      opacity: 0.7;
    }
  }
}

@media (min-width: 768px) {
  .ons-scroll[hookname=main] > .scroll-overlay > .bar {
    right: 2px;
    width: 4px;
  }
}

main.ons-scroll {
  > .scroll-overlay > .scroll-top {
    border-bottom: 2px solid var(--yellow);
    background: var(--black) !important;

    &:hover {
      border-bottom: 2px solid var(--red);

      > .icon * {
        fill: var(--red);
      }
    }

    > .icon * {
      fill: var(--yellow);
    }
  }
}


header > .box > p.ons-text {
  color: #9a9a9a;
}


/* überschrift */
.ons-heading.default > .box {
  color: var(--yellow);
  text-transform: none;
  font-weight: 700;
}

.ons-layout.dropdown > .box > * > .box {
  background: var(--black);
  color: var(--light-gray);
  font-size: 15px;
  font-weight: 500;

  &:hover {
    background: var(--yellow) !important;
    text-decoration: none !important;
    text-shadow: 0 0 2px rgba(36, 36, 36, 1);
  }
}

.ons-layout.dropdown > .box > .spacer {
  border-color: var(--gray);
}


/* Boxes */
.ons-image.busy > .box,
.ons-image.done > .box,
.ons-image.error > .box {
  background-color: var(--black);
}

.payment-hook .ons-image.busy > .box,
.payment-hook .ons-image.done > .box,
.payment-hook .ons-image.error > .box {
  background-color: transparent !important;
}

.frame {
  background: var(--black);
  background-clip: content-box;
}

.frame .frame {
  background-color: #1e1e1e;
}

.feed-box .ons-list > .box > * > .box {
  background: #1e1e1e;
}

.ons-item.tile > .box .label {
  font-weight: 700;
}

.ons-item.movie-hd {
  font-weight: 400;
  color: var(--yellow);
  background: var(--gray);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 3px 5px;
  top: 5px;
}

.ons-item.movie-hd.movieDetail {
  display: none!important;
}


/* OTM */
.aotm-widget {
  font-weight: 700;
  font-size: 30px;

   > .box > .value {
     font-size: 26px;
   }
}

.ons-text.aotm-widget {
  font-size: 20px;
  font-weight: 700;
}


/* Buttons */
.ons-item.button.cancel > .box,
.ons-list + .ons-item.button > .box {
  background: var(--gray);
  border: none;
  text-decoration: none;
}

.ons-item.button.cancel > .box:hover,
.ons-list + .ons-item.button > .box:hover {
  background: #909090;
  text-decoration: none;
}

.ons-item.button > .box > .icon > * {
  fill: white;
}

.ons-item.button.cancel > .box > .icon > * {
  fill: white;
}

.ons-item.button.cancel > .box:hover > .icon > * {
  fill: white;
}

[onsw-payment-new-window] > .box > .buttons > .box > .ons-item.no > .box {
  background: linear-gradient(to bottom, #e20000 0%, #940000 60%, #640505 100%);
}

/* Overlay */
.ons-layout.overlay-toolbar > .box {
  background: var(--dark-gray);
}

.ons-layout.overlay-content {
  background: var(--dark-gray);
}

.ons-layout.overlay-toolbar.transactionResultOverlay > .box,
.ons-layout.overlay-toolbar.paybackResultOverlay > .box,
.ons-layout.overlay-toolbar.payment > .box {
  background: white;

  > .ons-item > .box {
    color: var(--black) !important;
  }
}

.overlay-toolbar > .box > .ons-item.ons-heading > .box,
.overlay-toolbar > .box > .ons-item.active > .box {
  color: var(--yellow);
}

.overlay-toolbar > .box > .ons-item[click-action] > .box:hover {
  color: white;
}

.overlay-toolbar > .box > .ons-item.active > .box > .icon *,
.overlay-toolbar > .box > .ons-item[click-action] > .box:hover > .icon * {
  fill: var(--yellow);
}

[onsw-picture-slideshow] .overlay-toolbar > .box > .ons-item > .box {
  color: var(--yellow);
  font-weight: 700;
}

.ons-image.pictureSlideshow > .box,
.ons-image.pictureSlideshow.done > .box,
.ons-image.pictureSlideshow.busy > .box {
  background-color: var(--dark-gray) !important;
}

@media (min-width: 1024px) {
  .overlay-toolbar.payment > .box > .ons-item > .box > .icon * {
    fill: var(--black);
  }
}

.overlay-toolbar.bonusOverlay > .box > .ons-item:not(.WellCum) > .box > .icon * {
  fill: var(--black);
}

.livecamRegardOverlay .ons-heading {
  font-size: 15px;

  > span {
    color: var(--yellow);
    font-weight: 700;
    font-size: 32px;
  }
}

.pageLogin .ons-item.link > .box {
  color: white;

  &:hover {
    color: var(--yellow);
  }
}

.pageLogin .ons-item.link.signupLong  > .box {
  color: var(--yellow);

  &:hover {
    color: white;
  }
}


/* Pagination */
[onsw-pagination] > .box {
  font-weight: 500;
  font-size: 16px;
  color: #a2a1a1;
}
body.desktop [onsw-pagination] > .box > .ons-item > .box:hover {
  background: #444;
  color: white;
}
[onsw-pagination] > .box > .ons-item.current > .box {
  background: var(--yellow);
  color: var(--black);
  font-weight: 700;
}
[onsw-pagination] > .box > .ons-item.arrow > .box {
  background: #535353;
}
[onsw-pagination] > .box > .ons-item.arrow > .box > .icon * {
  fill: white;
}
body.desktop [onsw-pagination] > .box > .ons-item.arrow > .box:hover > .icon * {
  fill: var(--yellow);
}


/* Stock */
[onsw-stock-indicator].movieDetail > .box > .value,
[onsw-stock-indicator].picture > .box > .value {
  border-radius: 0;
}
[onsw-stock-indicator].picture.invalid > .box > .label {
  background-color: #c40000;
  border-bottom: 1px solid #d10000;
}
[onsw-stock-indicator].valid > .box > .icon * {
  fill: var(--green);
}

.video-js .vjs-control-bar {
  background-color: rgba(0, 0, 0, .7) !important;
}
.video-js .vjs-load-progress {
  background: rgba(0, 0, 0, .4) !important;
}
.video-js .vjs-load-progress div {
  background: var(--yellow) !important;
}


/* VIP */
.table1 td {
  padding: 10px 15px;
}
.table1 td:nth-child(1) {
  border-right: 2px solid transparent;
}
.table1 tr:nth-child(odd) > td {
  background: #1e1e1e;
  background-clip: padding-box;
}
.table1 th {
  text-align: left !important;
  padding: 0 15px 10px 15px;
}

/* Sedcard */
onsw-more-about-me dt {
  font-weight: 600;
  color: var(--yellow);
  margin-bottom: 5px;
}
onsw-more-about-me h4 {
  font-size: 15px;
  color: var(--green);
}

/* Feed */
.small-feed-box:hover {
  background: var(--gray);
}
.feeditem header p {
  color: #9a9a9a;
}

/* ZIP */
.amateurPool-selector-by-zip .land {
  fill: #4c4c4c;
}

onsw-open-dialog-button.ons-item.tile > .box > .icon *,
onsw-open-dialog-button.ons-item.amateurToolbar > .box > .icon * {
  fill: white;
}
[onsw-loader] .box path,
[onsw-loader] .box circle {
  stroke: var(--yellow);
}


/* Forms */
[onsw-chosen] > .box > .info,
.ons-form > .box > label > input:not([type="checkbox"]),
.ons-form > .box > label > file-upload,
.ons-form > .box > label > select,
.ons-form > form > label > textarea {
  border: var(--input-border);
  font: var(--input-font);
  color: var(--input-color);
  background: var(--input-bg);
}

.ons-form > .box > label > textarea {
  padding: 5px;
}

file-upload:before {
  padding: 3px 10px;
  height: 25px;
  color: white;
  background: var(--light-gray);
}

.ons-form > .box > label > input:not([type="checkbox"]):focus,
.ons-form > .box > label > file-upload:focus,
.ons-form > .box > label > textarea:focus,
.ons-form > .box > label > select:focus {
  border: var(--input-border-focus);
  outline: none;
  box-shadow: none;
}

.ons-form > form > label > span {
  font-size: 14px;
  color: white;
}

[onsw-facet-filter] > .box {
  padding: 0;
  background: unset;
}
[onsw-facet-filter] > .box > div:not(:first-child) {
  margin: 0;
}

[onsw-chosen] > .box > .available > .box > .option {
  background: white;

  &:hover {
    background: linear-gradient(to bottom, #47a813 0%, #2e8102 100%);
  }
}
[onsw-chosen] > .box > .selected > .option,
[onsw-chosen] > .box > .selected > .add {
  background: var(--gray);
  color: var(--yellow);
}

[onsw-chosen] > .box > .selected > .option::after,
[onsw-chosen] > .box > .selected > .add > span {
  top: -2px;
}

[onsw-chosen] > .box input,
[onsw-chosen] > .box .available,
[onsw-chosen] > .box > .info {
  color: #555;
}

[onsw-chosen] > .box input {
  padding: 0 5px;
  height: 30px;
  border-left: none;
  border-right: none;
}

[onsw-amateur-common-filter] > .box > div.suggestions {
  border: none;
  background-color: white;
}
[onsw-amateur-common-filter] > .box > div.suggestions .suggestion.selected {
  background: #f4ebc3;
  color: #333;
}


onsw-template p.help-block,
.ons-form > form > p.small {
  color: var(--light-gray);
  font-size: 12px;
  line-height: 1.3;
}

/* Notify & Error */
.box > .notify {
  border: none !important;
  background: var(--green);
  color: white;
  font-weight: 600;
  text-shadow: 0 0 2px black;
}

.error > .box > .notify {
  background: var(--red);
  color: white;
  font-weight: 600;
  text-shadow: 0 0 2px black;
}

/* Close */
.notify.closeable > .box::before {
  fill: white;
  opacity: .7;
}
.notify.closeable > .box:hover::before {
  fill: white;
  opacity: 1;
}


/* Attachments */
[onsw-attachments] .attachment {
  background: #1e1e1e;
}

[onsw-attachments] .attachment:hover {
  background: #333;
}

[onsw-attachments] .delete > .icon {
  fill: #666;
}

[onsw-attachments] .delete:hover > .icon * {
  fill: var(--yellow);
}

[onsw-attachments] .icon.movie * {
  fill: #858585;
}


/* Livecam Tile */
.ons-item.small-tile > .box,
.ons-item.small-livecam {
  background: rgba(0, 0, 0, 0.4);
}

.ons-item.small-livecam > .box {
  color: var(--yellow);
}

figcaption.ons-item.livecamTile > .box:hover {
  color: var(--yellow);
}

.ons-text.emptyList > .box {
  color: #979797;
  padding: 15px;
  background: var(--black);
}

.ons-text.emptyList > .box > .text::before {
  background-image: url(../img/noResult.png);
  background-size: contain;
}

[onsw-picture-set-info],
[onsw-movie-info] {
  pointer-events: all;
}

.layout-footer {
  color: white;
}

/* Payment */
[onsw-payment-methods] .method .box {
  min-height: 82px;
}

[onsw-payment-country] > form.box > label {
  > span {
    font-size: 14px;
    color: var(--black);
  }

  > select {
    border: 1px solid #ddd;
  }
}

.ons-heading.payment > .box {
  font-weight: 600;
}

[onsw-payment-progress].step li.active div,
[onsw-payment-progress].step li.finalized div {
  background: var(--yellow);
}

[onsw-payment-progress].step li.active p {
  color: var(--yellow);
  text-shadow: 0 0 1px rgba(0,0,0,.7);
}

[onsw-payment-progress].step .bar div {
  background: var(--yellow);
}

[onsw-payment-mobile-payment].payment .package > .box,
[onsw-payment-packages].payment .package > .box,
[onsw-payment-methods] .method .box {
  border-color: #ddd;
}

[onsw-payment-mobile-payment].payment .package:hover > .box,
[onsw-payment-packages].payment .package:hover > .box,
[onsw-payment-methods] .method:hover .box {
  background-color: #f5f5f5;
}

[onsw-payment-mobile-payment].payment .package.active > .box,
[onsw-payment-methods] .method.active .box {
  background-color: #f5f5f5;
  border: 2px solid var(--yellow);
  color: #1d2129;
}

[onsw-payment-packages].payment .package .name {
  color: var(--yellow);
  text-shadow: 0 0 1px rgba(0,0,0,.7);
  font-weight: 600;
}

[onsw-payment-mobile-payment].payment .package > .box  > .ons-item,
[onsw-payment-packages].payment .package.packageSelected > .box  > .ons-item,
[onsw-payment-packages].payment .package > .box  > .ons-item {
  background: var(--yellow);
  color: var(--black);
}

[onsw-payment-packages].payment .package.packageSelected > .box {
  border-color: var(--yellow);
}

[onsw-payment-packages].payment .package:not(.withBonus).packageSelected > .box  > .ons-item {
  padding: 2px 4px;
  right: 20px;
}

[onsw-payment-packages].payment .package .value.coins .default {
  font-size: 21px;
}

[onsw-payment-mobile-payment].payment .package .value.coins .bonus,
[onsw-payment-packages].payment .package .value.coins .bonus {
  color: #c80000;
  font-size: 26px;
}

[onsw-payment-mobile-payment].payment .package.withBonus .value.coins .default:before,
[onsw-payment-packages].payment .package.withBonus .value.coins .default:before {
  border-color: #c80000;
}

[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.bonus:after,
[onsw-payment-packages].payment .package > .box  > .ons-item.bonus:after {
  color: var(--black);
}

[onsw-payment-mobile-payment].payment .package > .box > .ons-item.bonus .label,
[onsw-payment-packages].payment .package > .box > .ons-item.bonus .label {
  font-weight: 400;
}
.ons-item.backLink > .box > .label::before {
  border-right-color: #666;
}

[onsw-payment-methods] .method .methodVerifiedFlag {
  color: var(--green);
}
[onsw-payment-methods] .method .methodVerifiedFlag > span {
  color: var(--gray);
}

.overlay-toolbar.payment.payback > .box > .ons-item > .box {
  color: #c80000;
}

.payment.payback h2 {
  color: #c80000;
  font-size: 16px;
}

.payment.payback p.intro {
  font-size: 14px;
  font-weight: 700;
}

.payment.payback p span {
  color: #c80000;
}

.payment .ons-item.link > .box,
.payment a {
  color: #1a1a1a;
}


[onsw-payment-packages].rebuy .package > .box {
  font-size: 14px;
}

[onsw-amateur-topic-badge] > .box {
  font-weight: 700;
}
[onsw-amateur-topic-badge] > .box * {
  filter:drop-shadow(1px 1px 1px #b57300);
}


[onsw-amateur-topic-badge] > .box .label {
  filter:drop-shadow(1px 1px 1px #b57300);
  font-weight: 700;
  font-size: 13px;
}
[onsw-amateur-topic-badge] > .box .icon{
  filter:drop-shadow(0px 1px 0px #b57300);
}
