[onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
  background-color: var(--black);
  background-image: url(../img/signup_background2.jpg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
}

@media (max-width: 619px) {
  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
    background-image: none;
  }
}

[hookname="welcomeGuest"] .ons-item.link > .box {
  color: var(--yellow);
}

[hookname="welcomeGuest"] .ons-heading > .box {
  font-size: 32px;
  color: var(--yellow);
}

@media (max-width: 1279px) {
  [hookname="welcomeGuest"] .ons-heading > .box {
    line-height: 1;
  }
}

[hookname="welcomeGuest"].ons-layout.columns > .box > .col50-xs {
    max-width: 400px !important;
}
