/* ero */
@import '../css/messenger/voicemessage.css';
@import '../css/messenger/voicemessageRecorder.css';

.layout-toolbar.messenger-voice {
  --voicemessage-msg-out-wave-color: #c0bfbf;
  --voicemessage-msg-out-wave-progress-color: var(--yellow);
  --voicemessage-msg-out-btn-color: var(--yellow);
  --voicemessage-msg-out-btn-color-hover: white;
  --voicemessage-msg-out-btn-bg-active: var(--yellow);
  --voicemessage-msg-out-btn-color-active: var(--black);
  --voicemessage-msg-out-duration-color: #c0bfbf;
  --voicemessage-msg-in-wave-color: var(--black);
  --voicemessage-msg-in-wave-progress-color: var(--gray);
  --voicemessage-msg-in-btn-color: var(--gray);
  --voicemessage-msg-in-btn-color-hover: var(--black);
  --voicemessage-msg-in-btn-bg-active: var(--black);
  --voicemessage-msg-in-btn-color-active: var(--yellow);
  --voicemessage-msg-in-duration-color: var(--gray);
}

.messenger-voice-recorder {
  --record-btn-bg: var(--gray);
  --record-btn-border: 1px solid transparent;
  --record-btn-hover-bg: var(--green);
  --voicemessage-recorder-wave-color: var(--black);
  --voicemessage-recorder-wave-progress-color: var(--yellow);
  --voicemessage-recorder-btn-color: var(--gray);
  --voicemessage-recorder-btn-color-hover: var(--black);
  --voicemessage-recorder-btn-bg-active: var(--black);
  --voicemessage-recorder-btn-color-active: white;
  --voicemessage-duration-color: var(--gray);
}
