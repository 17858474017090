.layout-container.messenger > .box {
  background-color: var(--dark-gray);
  border-left: 1px solid var(--black);
  color: white;
  > .items > .scroll-overlay {

    > .scroll-top,
    > .page-down {
      background: #333;
    }

    > span > .icon * {
      fill: white;
    }
  }
}
.messenger-widget:not(.tabs) {
  background-color: var(--dark-gray);
}

[onsw-nickname-filter] > .box {
  border-bottom: 1px solid var(--black);
}

:hover > .contact-box,
[onsw-amateur-label].messengerDialog > .box:hover {
  background: var(--gray);
}

.layout-container.messenger [onsc-message-count] > .box > .value {
  background-color: var(--green);
  width: 16px;
  height: 16px;
  font-size: 14px;
  font-weight: 600;
}

.layout-container.messenger [onsc-message-count] > .box:hover > .value {
  color: white;
  background: var(--red);
}

@keyframes blubb {
  from { transform: scale(1); background-color: var(--green); }
  to { transform: scale(1.4); background-color: var(--green); }
}

onsw-message-notification > .box {
  background: var(--yellow);
  color: white;
  text-shadow: 0 0 2px rgba(36, 36, 36, 1);
  line-height: 1;
}

onsw-message-notification > .box > span {
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  color: var(--dark-gray);
  vertical-align: super;
}

onsw-message-notification > .box > .icon {
  padding: 5px 10px;
}

onsw-message-notification > .box .icon * {
  fill: var(--black);
}

[onsw-amateur-label].messengerDialog > .box > .name {
  font-size: 16px;
  color: var(--yellow);
}

[onsw-amateur-label].messengerDialog > .box > .goto {
  color: var(--yellow);
  text-decoration: underline;
  font-weight: 600;
}

[onsw-amateur-label].messengerDialog > .box:hover > .goto {
  text-decoration: none;
}

.messenger-dialog > onsw-messenger-message-box {
  border-top: 2px solid var(--black);
  border-bottom: 1px solid var(--black);
}

.messenger-dialog > onsw-message-composer {
  background: unset;
}

onsw-messenger-message-box .start {
  color: #bdbdbd;
  text-align: center;
  padding: 10px 0 0;
}

onsw-messenger-message-box > .box > .msg.in > .box {
  background: #ffbd2f;
  color: var(--black);
}

onsw-messenger-message-box > .box > .msg.out > .box {
  background: var(--black);
  color: white;
}

onsw-messenger-message-box > .box > .msg > .meta {
  color: #c0bfbf;
  font-size: 12px;
}

onsw-messenger-message-box {
  .msg.out .ons-item.link {
    > .box {
      color: var(--yellow);

      &:hover {
        color: white;
      }

      > .icon {
        fill: white;
      }
    }
  }

  .msg.in .ons-item.link {
    > .box {
      &:hover {
        color: var(--black);
      }

      > .icon {
        fill: var(--black);
      }
    }
  }
}


onsw-messenger-message-box > .box > .msg.match > .box,
onsw-messenger-message-box > .box > .msg.kiss > .box,
onsw-messenger-message-box > .box > .msg.sexicon > .box {
  font-weight: 700;
}

onsw-messenger-message-box > .box > .msg.match > .box,
onsw-messenger-message-box > .box > .msg.kiss > .box {
  color: #d70000;
}

onsw-messenger-message-box > .box > .msg.in > .box::after {
  border-right-color: #ffbd2f;
}

onsw-messenger-message-box > .box > .msg.out > .box::after {
  border-left-color: var(--black);
}

onsw-messenger-message-box > .box > .msg.ng-enter-active > .box {
  background-color: var(--green);
  color: black;
}

onsw-messenger-message-box > .box > .msg.out.ng-enter-active > .box::after {
  border-left-color: var(--green);
}

onsw-messenger-message-box > .box > .msg.in.ng-enter-active > .box::after {
  border-right-color: var(--green);
}

.flying-chat-box {
  background: var(--green) !important;
  color: white;
}

