/** ## erocommunity.com (ero) */
:root {
  /** ### Achievement */
  /* - `.list.packages` */
  --achievement-packages-width: 250px;

  /** ### amateurPool */
  .amateurPool-otm-shuffle {
    --otm-name-font: 700 15px var(--headline);
    --otm-age-font: 13px var(--headline);
  }

  .amateurPool-feed-item {
    --feed-item-icon-color: var(--green);
  }

  .amateurPool-sedcard-image {
    --sedcard-image-outline: 2px solid var(--yellow);
  }

  .amateurPool-selector-by-zip {
    --zip-selector-background: var(--black);
    --zip-selector-font: 600 32px var(--headline);
    --zip-selector-text-color: var(--yellow);
    --zip-selector-fill-hover: #545454;
  }

  .amateurPool-sedcard-header {
    --sedcard-heading-font: 700 21px/1 var(--headline);
    --sedcard-heading-from-font: 700 16px var(--headline);
    --sedcard-heading-from-color: var(--light-gray);
  }

  /** ### layout */
  .layout-container {
    --container-gap: 10px;
  }

  .layout-input {
    --input-border: 1px solid white;
    --input-border-focus: 1px solid var(--yellow);
  }

  .layout-item {
    --item-color: #fff;
    --item-background: #1e1e1e;
    --item-background-hover: var(--gray);
    --item-background-active: var(--light-gray);
    --item-highlight-color: var(--yellow);
    --item-heading-font: 700 15px var(--headline);
    --item-font: 400 12px var(--default-font);
  }

  .layout-link {
    --link-font: 15px var(--headline);
    --link-color: #fff;
    --link-background-hover: var(--black);
  }

  .layout-note {
    --layout-note-info-background: var(--green);
    --layout-note-info-color: white;
    --layout-note-warn-background: var(--yellow);
    --layout-note-warn-color: var(--black);
    --layout-note-error-background: var(--red);
    --layout-note-error-color: white;
  }

  .layout-panel {
    --panel-padding: 10px;
    --panel-gap: 10px;
    --panel-background: var(--black);
    --panel-color: white;
    --panel-heading-font: 700 16px var(--default-font);
    --panel-heading-font-color: var(--yellow);
    --panel-heading-text-transform: unset;
  }

  .layout-price {
    --price-font: 700 14px var(--headline);
  }

  .layout-toolbar {
    --toolbar-icon-color: var(--default-close-color);
    --toolbar-icon-color-hover: var(--default-close-color-hover);

    &.layout-tabs > .layout-link {
      font-weight: 700;
    }

    &.nova-dots {
      --dots-color: white;
      --dots-active-color: var(--yellow);
    }
  }

  .layout-input-toggle {
    --toggle-bg-off: var(--gray);
    --toggle-bg-on: var(--green);
  }

  .layout-header {
    --header-bg-color: linear-gradient(to bottom, #e20000 0%, #940000 60%, #640505 100%);
    --header-border-bottom: unset;
    --header-menu-border-color: rgba(255, 255, 255, .3);
    --header-menu-button-bg-color: transparent;
    --header-menu-button-icon-color: white;
    --header-menu-button-hover-icon-color: var(--yellow);

    --logo-xs-url: url(./img/logo-xs.svg);
    --logo-url: url(./img/logo.svg);
    --logo-max-width: 240px;

    --header-label-color: white;
    --header-label-font-size: 14px;
    --header-label-font-family: var(--headline);
    --header-label-text-shadow: none;

    &.user-header {
      --games-button-value-bg: #b4df80;
      --games-button-value-color: black;
      --coins-button-value-bg: var(--yellow);
      --coins-button-value-color: black;
      --messenger-button-value-bg: var(--green);
      --messenger-button-value-color: white;
      --cams-button-value-bg: var(--yellow);
      --cams-button-value-color: black;
      --vip-button-value-bg: var(--yellow);
      --vip-button-value-color: black;
      --friends-button-value-bg: var(--yellow);
      --friends-button-value-color: black;
    }

    &.guest-header {
      --header-login-gap: 5px;
      --header-button-padding: 3px 8px;

      .layout-link.preset-button {
        --link-background: var(--yellow);
      }
    }
  }

  .layout-textlink {
    --link-color: #fff;
    --link-text-decoration: none;
    --link-hover-text-decoration: underline;
  }

  .layout-tabs {
    --menu-item-label-color-hover: var(--menu-item-label-color-active);
    --menu-item-bg-hover: var(--menu-bg);
    --menu-item-bg-active: var(--menu-bg);
  }

  /** - toolbar-list */
  --toolbar-list-font: 700 16px var(--headline);
  --toolbar-list-color-hover: var(--yellow);
  --toolbar-list-background: rgba(0, 0, 0, 0.7);
  --toolbar-list-background-without-hover: rgba(0, 0, 0, 0.4);
  --toolbar-list-filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6));

  /** ### userpool */
  .userpool-mailing-config {
    --mailing-config-primary-color: var(--green);
    --mailing-config-primary-font: 700 16px var(--headline);
    --mailing-config-secondary-color: white;
    --mailing-config-secondary-font: 700 14px var(--headline);
    --mailing-config-warning-font: 700 14px var(--default-font);
    --mailing-config-warning-color: white;
  }

  /** regard */
  .ons-layout.overlay .layout-item.regard {
    --item-background: var(--black);
  }

  .layout-item.regard {
    --item-heading-font: 700 14px var(--headline);
    --regard-text-size: 12px;
  }

  /** ### moviePool */
  .fmotd-countdown {
    --fmotd-countdown-color: var(--yellow);
    --fmotd-countdown-text-shadow: 0 0 2px #7d4e06;
  }
}
