[onsw-hotornot] {
  margin: 10px;
}
[onsw-hotornot].ons-layout.group > .box {
  background-image: url(../img/hotornot/logo.png), url(../img/hotornot/bg.jpg);
  background-size: 340px, cover;
  background-repeat: no-repeat;
  background-position: 40px 5px, right;
}

.hotornot-pending {
  box-shadow: 0 -7px 7px #0000007a;
  background: linear-gradient(to bottom, rgb(125, 113, 113) 0%,rgb(21, 21, 21) 80%);
}

[onsw-item].hotornot {
  font-size: 28px;
  margin-top: 5px;
}
.name[onsw-item].hotornot {
  color: var(--yellow);
  font-weight: 700;
}
.hotornot-positive > .box > [onsw-item] {
  color: white !important;
  font-weight: 600;
}
[onsw-item].hotornot > .box > .value {
  font-size: 20px;
  color: white;
  font-weight: 600;
}
.hotornot-pending.pos1 > .box > [onsw-text].hotornot.from {
  font-size: 14px;
  color: white;
  font-weight: 600;
}
[onsw-text].hotornot.descr {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.ons-layout.textblock > .box > .ons-heading > .box {
  font-size: 30px;
  font-weight: 700;
  color: var(--yellow);
}

.hotbar > .box {
  background: linear-gradient(to bottom, rgb(60, 53, 53) 0%,rgb(27, 27, 27) 100%);
}

.hotbar > .box > [onsw-pin-button].active > .box > .icon * {
  stroke: var(--black);
  stroke-width: 1px;
}

.hotbar > .box > [onsw-pin-button] > .box > .icon *, .hotbar > .box > onsw-open-dialog-button > .box > .icon *, .hotbar > .box > [onsw-item] > .box > .icon * {
  fill: var(--black);
}

.hotornot-positives {
  border-top: 1px solid #ffffff2e;
  background: #383838ba;
}

.hotbar > .box > .ons-item > .box {
    box-shadow: 0 0 8px #170f0f;
}

.hotornot.icons > .icon:first-child * {
  fill: var(--red);
}
.hotornot.icons > .icon:last-child * {
  fill: var(--green);
  stroke: var(--green);
}
.hotbar > .box > [onsw-hotornot-button][type="hot"] > .box  {
  background-color: var(--green);
  border: 2px solid var(--green);
  padding: 6px;
}
[onsw-hotornot-button][type="hot"] > .box > .icon * {
  fill: white;
  stroke: white;
}
[onsw-hotornot-button][type="hot"] > .box:hover {
  border: 2px solid var(--green);
  background-color: white !important;
}
[onsw-hotornot-button][type="hot"] > .box:hover > .icon * {
  stroke: var(--green);
  fill: var(--green);
}
[onsw-hotornot-button][type="not"] > .box > .icon * {
  fill: var(--red);
}

.hotbar > .box > [onsw-hotornot-button][type="not"] > .box:hover {
  border: 2px solid var(--red);
  padding: 6px;
}
